<template>
  <div class="flex flex-col">
    <span
      class="-mt-10 font-extrabold text-blue-500 
      items-center inline-flex cursor-pointer"
      @click="$emit('goBack', 'payment-method')"
    >
      <img :src="assets.icons.sm.back" class="mr-2" alt="Back" />
      Back
    </span>
    <h1 class="text-34 mt-2 font-black tracking-tight mb-2">
      {{ businessName | sentenceCase }}
    </h1>
    <p class="text-12 font-normal mb-4 text-marketplace-blue text-opacity-75">
      Spread payment.
    </p>
    <div
      v-for="(m, index) in loanTenures"
      :key="`tenure-${index}`"
      class="border-0.7 rounded w-full min-h-10 bg-marketplace-lightgray
      cursor-pointer flex flex-col px-4 p-2 mt-4"
      :class="tenure === m.value ? 'active-tenure' : 'tenure-not-active'"
      @click="setTenure(m.value)"
    >
      <div class="flex justify-between items-center">
        <div class="inline-flex items-center">
          <div
            class="rounded-full w-6 h-6 flex justify-center items-center"
            :class="tenure === m.value ? 'bg-blue-500' : 'border-0.7 bg-white'"
          >
            <ion-icon
              v-if="tenure === m.value"
              name="checkmark-outline"
              class="text-white"
            ></ion-icon>
          </div>
          <div class="flex flex-col ml-4">
            <h2
              class="text-marketplace-blue text-10 tracking-tight"
              :class="tenure === m.value ? 'font-bold' : 'font-semibold'"
            >
              {{ m.title }}
            </h2>
          </div>
        </div>
      </div>
      <div
        v-if="tenure === m.value"
        class="mt-3 flex flex-col"
        ref="loan-details"
      >
        <div class="border-0.7 text-marketplace-blue border-opacity-75"></div>
        <div class="loan-details">
          <h1 class="loan-details-text">Order Amount</h1>
          <h1 class="loan-details-text">
            {{ orderAmount | formatAmount }}
          </h1>
        </div>
        <div class="loan-details">
          <h1 class="loan-details-text">Amount to pay now</h1>
          <h1 class="loan-details-text">
            {{ amountToPayNow | formatAmount }}
          </h1>
        </div>

        <div
          class="border-0.7 text-marketplace-blue border-opacity-75 mt-5"
        ></div>
        <div class="loan-details">
          <h1 class="loan-details-text">Interest rate</h1>
          <h1 class="loan-details-text">
            {{ interestRate }}
          </h1>
        </div>

        <div class="loan-details">
          <h1 class="loan-details-text">Repayment amount</h1>
          <h1 class="loan-details-text">
            {{ monthlyRepayment }}
          </h1>
        </div>

        <div
          class="border-0.7 text-marketplace-blue border-opacity-75 mt-5"
        ></div>
        <div class="loan-details">
          <h1 class="loan-details-text">Total repayment amount</h1>
          <h1 class="loan-details-text">
            {{ totalRepayment }}
          </h1>
        </div>
      </div>
    </div>

    <div class="flex flex-row items-center mt-4">
      <checkbox v-model="termsAndCondition" name="terms-and-condition" />
      <label for="terms-and-condition" class="text-sm ml-1">
        I accept the
        <a
          :href="loanAgreement"
          rel="noopenner"
          target="_blank"
          class="text-blue-500 underline font-bold"
        >
          Loan Terms & Condition
        </a>
      </label>
    </div>

    <repayment-card
      ref="repaymentCard"
      :cards="repaymentCards"
      :amount-to-pay="amountToPayNow"
      @pay-with-repayment-card="payWithRepaymentCard"
      @pay-with-new-card="equityPaymentSuccessful"
      :key="componentKey"
    />

    <div class="mt-8">
      <template v-if="activeLoan">
        <button
          type="button"
          @click="displayErrorInformation"
          class="btn btn-blue py-6 w-full"
          :disabled="disableButton"
        >
          Continue - Pay Now {{ amountToPayNow | formatAmount }}
        </button>
      </template>
      <template v-if="!activeLoan && noRepaymentCards">
        <paystack
          type="button"
          class="btn btn-blue py-6 w-full"
          :amount="amountToPayNow"
          :callback="equityPaymentSuccessful"
          :disabled="disableButton"
        >
          Continue - Pay Now {{ amountToPayNow | formatAmount }}
        </paystack>
      </template>
      <template v-else-if="!activeLoan && hasRepaymentCards">
        <button
          type="button"
          class="btn btn-blue py-6 w-full"
          @click="$refs.repaymentCard.open()"
          :disabled="disableButton"
        >
          Continue - Pay Now {{ amountToPayNow | formatAmount }}
        </button>
      </template>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  inheritAttrs: false,
  components: {
    RepaymentCard: () => import("./RepaymentCard")
  },
  data() {
    return {
      tenure: null,
      paymentSummary: this.$options.resource({}),
      activeLoan: true,
      componentKey: 0,
      termsAndCondition: false,
      loading: false
    };
  },
  computed: {
    businessName() {
      return this.$attrs?.vendor?.business_name;
    },
    loanTenures() {
      let tenure = [];
      for (var i = 1; i < 6; i++) {
        const month = i + 1;
        tenure.push({ value: month, title: `Pay in ${month} months` });
      }
      return tenure;
    },
    order() {
      return this.$attrs?.order;
    },
    orderAmount() {
      return this.order?.amount?.value;
    },
    summary() {
      return this.paymentSummary?.data;
    },
    interestRate() {
      return this.summary?.interest_rate;
    },
    creditApproved() {
      return this.$options.filters.formatAmount(this.summary?.credit_approved);
    },
    monthlyRepayment() {
      return `${this.$options.filters.formatAmount(
        this.summary?.monthly_repayment
      )} x ${this.tenure} months`;
    },
    amountToPayNow() {
      return this.summary?.amount_to_pay_now;
    },
    totalRepayment() {
      return this.$options.filters.formatAmount(this.summary?.total_repayment);
    },
    repaymentCards() {
      return this.resources.repaymentCards?.data;
    },
    noRepaymentCards() {
      return this.repaymentCards.length === 0;
    },
    hasRepaymentCards() {
      return this.repaymentCards.length > 0;
    },
    disableButton() {
      return !this.tenure || !this.termsAndCondition || this.loading;
    },
    loanAgreement() {
      return "https://credpal-loan.web.app/terms.html";
    }
  },
  mounted() {
    this.$watch("tenure", () => {
      $(this.$refs["loan-details"]).addClass("slideInDown");
      this.componentKey += 1;
    });

    this.$watch("paymentSummary.data", () => {
      this.paymentSummary?.data?.exist_loan
        ? (this.activeLoan = true)
        : (this.activeLoan = false);
    });
  },
  methods: {
    async setTenure(tenure) {
      this.tenure = tenure;
      await this.getSpreadPaymentSummary(tenure);
    },
    async getSpreadPaymentSummary(tenure) {
      this.paymentSummary.setLoading(true);
      await this.sendRequest(
        "personal.marketplace.paymentSummary.spreadPaymentSummary",
        {
          data: {
            amount: this.orderAmount,
            tenure: tenure
          },
          success: response => (this.paymentSummary.data = response.data.data),
          error: error => console.log(error)
        }
      );
      this.paymentSummary.setLoading(false);
    },
    equityPaymentSuccessful(data) {
      this.loading = true
      if (data?.status === "success") {
        this.$emit("confirmOrder", {
          ...data,
          tenure: this.tenure,
          method: "new-card",
          closeLoading: () => (this.loading = false)
        });
      }
    },
    payWithRepaymentCard(authorizationCode) {
      this.loading = true
      this.$emit("confirmOrder", {
        authorizationCode,
        tenure: this.tenure,
        method: "repayment-card",
        closeLoading: () => (this.loading = false)
      });
    },
    async getCards(forced = true) {
      this.loadResource("repaymentCards", forced);
    },
    displayErrorInformation() {
      return this.$error({ title: "You currently have an active loan" });
    }
  }
};
</script>
<style lang="scss" scoped>
.slideInDown {
  -webkit-animation: slide-down 0.5s forwards;
  animation: slide-down 0.5s forwards;
}
.active-tenure {
  background: rgba(39, 79, 237, 0.04);
  @apply border-blue-500;
}
.tenure-not-active {
  @apply bg-marketplace-lightgray;
  @apply border-marketplace-gray;
}
.loan-details {
  @apply flex;
  @apply justify-between;
  @apply items-center;
  @apply text-marketplace-blue;
  @apply mt-4;
  &:last-child {
    @apply mb-3;
  }
  &-text {
    @apply text-11;
    @apply font-normal;
  }
}
@-webkit-keyframes slide-down {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
